import { Box, Flex, Link, HStack, IconButton, Text } from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <Box as="footer" bg="blue.500" color="white" py={5}>
      <Flex
        direction={{ base: "column", md: "row-reverse" }}
        justify="space-between"
        align="center"
        px={8}
        maxW="1080px"
        mx="auto"
      >
        <HStack spacing={4}>
          <IconButton
            as={Link}
            target="_blank"
            href="https://www.facebook.com/startekaustralia"
            aria-label="Facebook"
            icon={<FaFacebook />}
            variant={"outline"}
            _hover={""}
            color="white"
            fontSize="20px"
          />
          <IconButton
            as={Link}
            href="https://www.instagram.com/startek_au/"
            aria-label="Instagram"
            icon={<FaInstagram />}
            variant={"outline"}
            _hover={""}
            color="white"
            fontSize="20px"
          />
          <IconButton
            as={Link}
            href="https://www.youtube.com/channel/UCZ9yNb94w_SFsz7rQ1LGkyw"
            aria-label="YouTube"
            icon={<FaYoutube />}
            variant={"outline"}
            _hover={""}
            color="white"
            fontSize="20px"
          />
        </HStack>
        <Flex direction={"column"}>
          <HStack
            spacing={6}
            align="center"
            display={"flex"}
            mt={{ base: 4, md: 0 }}
          >
            <Link href="https://destinationaus.com/">Home</Link>
            <Link href="https://destinationaus.com/tools/">Tools</Link>
            <Link href="https://destinationaus.com/blog/">Blog</Link>
            <Link href="https://destinationaus.com/contact-us/">
              Contact Us
            </Link>
          </HStack>
          <Text textAlign="center" mt={{ base: 4, md: 2 }}>
            &copy; StarTek AU {new Date().getFullYear()}
          </Text>
          <Link
            mt={1}
            textAlign="center"
            textDecoration={"underline"}
            href="https://destinationaus.com/privacy-policy/"
          >
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Footer;
