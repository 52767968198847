import "./App.css";
import Footer from "./components/Footer";
import NavBar from "./components/Navbar";
import UniversityFinder from "./pages/UniversityFinder";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <>
      <ChakraProvider>
        <NavBar />
        <UniversityFinder />
        <Footer />
      </ChakraProvider>
    </>
  );
}

export default App;
